import React from 'react';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import IconHost from './components/IconHost';
import i18n from './i18n';
import './index.css';
import ReactDOM from 'react-dom';

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <React.StrictMode>
            <App />
            <IconHost />
        </React.StrictMode>
    </I18nextProvider>,
    document.getElementById('root')
);
